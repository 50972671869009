import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/Homepage";
// import SessionPage from "./components/Sessionpage";
import SessionPage from "./components/session/SessionPage";
import FoodDojo from "./components/foodDojo";
import SessionDetailPage from "./components/sessionDetailPage";
import { VenueProvider } from "./context/VenueContext";
import JoinSessionScreen from "./components/joinSessionScreen";
import MatchedRestaurant from "./components/matchedResturant";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import FeedbackIcon from "@mui/icons-material/Feedback"; // Import the icon for feedback
import { useAuth } from "./utils/useAuth";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { AmplifyComponents } from "./utils/amplifyComponents";
import SessionWrapped from "./components/SessionWrapped";

Amplify.configure(awsExports);

const App = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const { user, userSignOut } = useAuth();
  useEffect(() => {
    if (!window.DD_RUM) {
      window.DD_RUM = true; // Set a flag indicating RUM is initialized
    }

    if (!window.DD_LOGS) {
      window.DD_LOGS = true; // Set a flag indicating LOGS is initialized
    }
    // Set global context for RUM and LOGS
    datadogLogs.setGlobalContext({
      session_id: datadogRum.getGlobalContext().session_id,
    });
  }, [user]);

  // const handleFeedbackClick = () => {
  //   const encodedMessage = encodeURIComponent(
  //     "Text feedback to the Dinder team, please include any screenshots and a description of what happened!"
  //   );
  //   // This will attempt to open the default messaging app with the number pre-filled and a body message
  //   window.location.href = `sms:+15129007493&body=${encodedMessage}`;
  // };

  const handleFeedbackClick = () => {
    // URL of the Google Form
    const url =
      "https://docs.google.com/forms/d/e/1FAIpQLSeAo5JrWEari3s20esj7s0qIWbmR7NUD0uFbvO0Cbns_BsJ7A/viewform?usp=sf_link";

    // Open the URL in a new window/tab
    window.open(url, "_blank");
  };

  const handleSignOut = () => {
    console.log("signing out");
    userSignOut();
  };

  const ProtectedRoute = ({ children }) => {
    const { route } = useAuthenticator((context) => [context.route]);
    return route === "authenticated" ? (
      children
    ) : (
      <Authenticator
        loginMechanisms={["email"]}
        initialState="signUp"
        signUpAttributes={["email", "name", "phone_number"]}
        components={AmplifyComponents}
      >
        {/* {({ signOut, user }) => (
          <main>
            <h1>Hello {user.username}</h1>
            <button onClick={signOut}>Sign out</button>
          </main>
        )} */}
      </Authenticator>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <VenueProvider>
        <Router>
          {/* Top bar */}
          <div
            className="top-bar"
            style={{
              display: "flex", // Added flex display
              alignItems: "center", // Center items vertically
              justifyContent: "space-between", // Space between the items
              height: "10vh",
              minHeight: "50px",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0, // Ensure it spans the full width
              padding: "0 20px", // Add some padding on the sides
              backgroundColor: "white", // Your existing background color
              borderBottom: "2px solid #e0e0e0", // Added bottom border
              zIndex: 10,
            }}
          >
            {user && <Button onClick={handleSignOut}>Sign out</Button>}
            <IconButton color="primary" onClick={handleFeedbackClick}>
              <FeedbackIcon />
              <span style={{ fontSize: "0.75rem" }}>Problem? Suggestion?</span>
            </IconButton>
          </div>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
              path="sessions/:sessionId/join"
              element={<JoinSessionScreen />}
            />
            {/* Protected Routes */}
            <Route
              path="/session"
              element={
                <ProtectedRoute>
                  <SessionPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/sessions/:sessionId/swipe"
              element={
                <ProtectedRoute>
                  <FoodDojo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sessions/:sessionId"
              element={
                <ProtectedRoute>
                  <SessionDetailPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/match/:resturantId"
              element={
                <ProtectedRoute>
                  <MatchedRestaurant />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sessionWrapped/:sessionId"
              element={
                <ProtectedRoute>
                  <SessionWrapped />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </VenueProvider>
    </ThemeProvider>
  );
};

const AppWithAuthenticator = () => (
  <Authenticator.Provider>
    <App />
  </Authenticator.Provider>
);

// export default App;
export default AppWithAuthenticator;

// export default withAuthenticator(App);
// return (
//   <ThemeProvider theme={theme}>
//     <CssBaseline />
//     <RestaurantProvider>
//       <Router>
//         {/* Top bar */}
//         <div
//           style={{
//             height: "10vh",
//             minHeight: "50px",
//             position: "fixed",
//             top: 0,
//             width: "100%",
//             zIndex: 10,
//             backgroundColor: "white", // Set the background color to white
//           }}
//         >
//           {user && (
//             <Button
//               onClick={handleSignOut}
//               style={{ position: "absolute", top: 0, left: 0 }}
//             >
//               Sign out
//             </Button>
//           )}
//           {/* Other top bar content */}
//         </div>
//         {/* Main content area */}
//         <div
//           style={{
//             paddingTop: "10vh",
//             paddingBottom: "10vh",
//             minHeight: "80vh",
//             boxSizing: "border-box",
//           }}
//         >
//           {/* This pushes the content below the sign-out button */}
//           {/* This padding ensures content isn't hidden behind the button */}
//           {/* {user ? ( */}
//           {/* // Protected Routes */}

//           <Routes>
//             <Route path="/" element={<HomePage />} />
//             <Route
//               path="sessions/:sessionId/join"
//               element={<JoinSessionScreen />}
//             />
//             {/* </Routes> */}
//             {user && (
//               <Authenticator
//                 loginMechanisms={["email"]}
//                 signUpAttributes={["phone_number", "name"]}
//               >
//                 {/* <Routes> */}
//                 <Route path="/session" element={<SessionPage />} />
//                 <Route
//                   path="/sessions/:sessionId/swipe"
//                   element={<FoodDojo />}
//                 />
//                 <Route
//                   path="/sessions/:sessionId"
//                   element={<SessionDetailPage />}
//                 />
//                 <Route
//                   path="/match/:resturantId"
//                   element={<MatchedRestaurant />}
//                 />
//                 {/* </Routes> */}
//               </Authenticator>
//             )}
//           </Routes>
//         </div>

//         <div
//           style={{
//             height: "10vh",
//             minHeight: "50px",
//             position: "fixed",
//             bottom: 0,
//             width: "100%",
//             zIndex: 10,
//             backgroundColor: "white", // Set the background color to white
//           }}
//         >
//           <IconButton
//             color="primary"
//             onClick={handleFeedbackClick}
//             style={{
//               position: "absolute",
//               left: "50%",
//               transform: "translateX(-50%)",
//             }}
//           >
//             <FeedbackIcon />
//             <span style={{ fontSize: "0.75rem" }}>Problem? Suggestion?</span>
//           </IconButton>
//           {/* Other bottom bar content */}
//         </div>
//       </Router>
//     </RestaurantProvider>
//   </ThemeProvider>
// );
// };

// export default App;

// way to set up auth betterf

// App.js

// ... (other imports)

// const App = () => {
//   const { user } = useAuth();

//   // ... (other hooks and functions)

//   return (
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <RestaurantProvider>
//         <Router>
//           {/* ... (Top bar and other UI elements) */}

//           <Routes>
//             {/* Public routes that do not require authentication */}
//             <Route path="/" element={<HomePage />} />
//             <Route path="sessions/:sessionId/join" element={<JoinSessionSceen />} />
//             {/* ... other public routes */}

//             {/* Route for sign-in or sign-up */}
//             {!user && (
//               <Route
//                 path="/auth"
//                 element={
//                   <Authenticator
//                     loginMechanisms={['email']}
//                     signUpAttributes={['phone_number', 'name']}
//                   />
//                 }
//               />
//             )}

//             {/* Protected routes that require authentication */}
//             {user && (
//               <>
//                 <Route path="/session" element={<SessionPage />} />
//                 <Route path="/sessions/:sessionId/swipe" element={<FoodDojo />} />
//                 <Route path="/sessions/:sessionId" element={<SessionDetailPage />} />
//                 <Route path="/match/:resturantId" element={<MatchedRestaurant />} />
//                 {/* ... other protected routes */}
//               </>
//             )}
//           </Routes>

//           {/* ... (Bottom bar and other UI elements) */}
//         </Router>
//       </RestaurantProvider>
//     </ThemeProvider>
//   );
// };

// export default App;
