import React, { useContext, useEffect } from "react";
import { VenueContext } from "../context/VenueContext";
import { datadogRum } from "@datadog/browser-rum";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Stack,
  Box,
} from "@mui/material";
import confetti from "canvas-confetti";
import { withAuthenticator } from "@aws-amplify/ui-react";

const MatchedRestaurant = () => {
  const { matches } = useContext(VenueContext);
  const { restaurant } = matches; // Retrieve from context or perform a fetch

  useEffect(() => {
    confetti({
      zIndex: 999,
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });

    // If a matched restaurant is found, log it with Datadog
    if (matches) {
      datadogRum.addAction("Matched Restaurant Viewed", {
        restaurantName: matches.name,
        restaurantId: matches.id,
      });
    }
  }, [matches]);

  const handleSearchAgain = () => {
    window.open("https://www.dinder.io", "_blank");
  };

  if (!matches) {
    return <CircularProgress />;
  }

  if (!restaurant) {
    return <div>Loading matched restaurant details...</div>;
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{
        height: "100vh", // Adjusted to account for the top and bottom bars without additional padding
        width: "100vw", // Ensure the stack takes the full viewport width
        overflow: "auto", // Add scroll to the Stack if content overflows
        position: "relative", // Ensures that this stack is positioned relatively for absolute children
        boxSizing: "border-box", // Include padding and borders in the element's total width and height
        paddingTop: "10vh",
      }}
    >
      <Card
        raised
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          position: "relative", // Allows absolute positioning relative to the card
          bgcolor: "background.default", // Ensure there's a background for transparent images
        }}
      >
        <CardContent sx={{ textAlign: "center" }}>
          <Typography variant="h4" color="primary" gutterBottom>
            Restaurant Match!!
          </Typography>
        </CardContent>
        <CardMedia
          component="img"
          image={restaurant.imageUrl}
          alt={restaurant.name}
          sx={{ height: "40%", objectFit: "cover" }}
        />
        <CardContent
          sx={{
            flexGrow: 1,
            overflowY: "auto", // Changed from 'scroll' to 'auto' for better appearance when not needed
            "::-webkit-scrollbar": { width: "10px" },
            "::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 5px grey",
              borderRadius: "10px",
            },
            "::-webkit-scrollbar-thumb": {
              background: "darkgrey",
              borderRadius: "10px",
            },
            paddingBottom: "10vh", // Space for the button
          }}
        >
          <Typography gutterBottom variant="h4" component="div">
            {restaurant.name}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {restaurant.categories}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{ cursor: "pointer" }}
            onClick={() => window.open(restaurant.url, "_blank")}
          >
            {`${restaurant.price} - ${restaurant.rating} stars (${restaurant.reviewCount} reviews)`}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {`${restaurant.location}`}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {restaurant.displayPhone}
          </Typography>
          <Button
            size="small"
            color="primary"
            href={restaurant.url}
            target="_blank"
            sx={{ my: 2 }}
          >
            Learn More
          </Button>
          {/* ... Display other restaurant details ... */}
        </CardContent>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: "10vh", // Fixed height for button container
            display: "flex",
            justifyContent: "center", // Center the single button
            alignItems: "center",
            bgcolor: "white", // A distinct background for the button
            borderTop: "1px solid",
            borderColor: "grey.500",
            zIndex: 1,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => window.open("https://www.dinder.io", "_blank")}
            sx={{
              width: "50%", // Making the button take up half of the container width
              maxWidth: "300px", // Ensuring it doesn't get too wide on larger screens
            }}
          >
            Search Again
          </Button>
        </Box>
      </Card>
    </Stack>
  );
};

// export default withAuthenticator(MatchedRestaurant);
export default MatchedRestaurant;
