import React, { useEffect, useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { VenueContext } from "../context/VenueContext"; // Adjust path as needed
import { getSessionDetails } from "../services/apiService";
import {
  Container,
  Typography,
  Snackbar,
  Stack,
  Card,
  CardContent,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { datadogRum } from "@datadog/browser-rum";
import { Sms } from "@mui/icons-material";
import SwipeIcon from "@mui/icons-material/Swipe";
import ActionButton from "./actions/ActionButton";

const SessionDetailPage = () => {
  const { sessionId } = useParams();
  const { setVenues, setSessionId } = useContext(VenueContext);
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [linkShared, setLinkShared] = useState(false); // New state to track if the link has been shared
  const [snackbarMessage, setSnackbarMessage] = useState(""); // State to hold the Snackbar message

  useEffect(() => {
    let isMounted = true; // Flag to check the mounted state

    const fetchSessionDetails = async () => {
      if (!sessionId) return; // Return early if sessionId is not valid

      try {
        const sessionDetails = await getSessionDetails(sessionId);
        if (isMounted) {
          setVenues(sessionDetails.restaurants);
          setSessionId(sessionId);
          datadogRum.addAction("Session Details Fetched", {
            sessionId: sessionId,
            sessionDetails: sessionDetails,
          });
        }
      } catch (error) {
        if (isMounted) {
          setFetchError(error);
          datadogRum.addError("Failed to fetch session details", error);
        }
      }
    };

    fetchSessionDetails();

    return () => {
      isMounted = false; // Set the flag to false when the component unmounts
    };
  }, [sessionId, setSessionId, setVenues]);

  const sessionLink = `${window.location.origin}/sessions/${sessionId}/join`;

  const handleStartSwiping = () => {
    if (!linkShared) {
      // If the link hasn't been shared, inform the user to share the link first
      setSnackbarMessage("Share the link with a friend first!");
      setOpenSnackbar(true);
    } else {
      // If the link has been shared, navigate to the swiping page
      navigate(`/sessions/${sessionId}/swipe`);
      datadogRum.addAction("Start Swiping Clicked", { sessionId: sessionId });
    }
  };

  const copySessionLinkToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(sessionLink);
      setSnackbarMessage("Session link copied to clipboard!");
      setOpenSnackbar(true);
      datadogRum.addAction("Session Link Copied", {
        sessionId: sessionId,
        sessionLink: sessionLink,
      });
      setLinkShared(true); // Update state to indicate the link has been shared
    } catch (err) {
      console.error("Failed to copy: ", err);
      datadogRum.addError("Failed to copy session link", err);
    }
  };

  const sendLinkViaText = async () => {
    const encodedSessionLink = encodeURIComponent(sessionLink);
    setSnackbarMessage("Session link copied to clipboard!");
    setOpenSnackbar(true);
    window.open(
      `sms:?&body=Join my dinder session: ${encodedSessionLink}`,
      "_blank"
    );
    setLinkShared(true); // Update state to indicate the link has been shared
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      setOpenSnackbar(false);
      return;
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        pt: 4,
        pb: 4,
        paddingTop: "10vh",
        overflowY: "auto",
        position: "relative",
        flexGrow: 1,
      }}
    >
      <Card
        sx={{ maxWidth: 600, width: "100%", m: "0 auto", p: 2, boxShadow: 3 }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{ fontWeight: "bold", textAlign: "center" }}
          >
            Session Details
          </Typography>
          <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
            Dinder is better with two, share the session link below to invite a
            friend
          </Typography>
          <Typography variant="body2" sx={{ textAlign: "center", my: 1 }}>
            1) Send to a friend
          </Typography>
          <Typography variant="body2" sx={{ textAlign: "center", my: 1 }}>
            2) Swipe right on restaurants you like!
          </Typography>
          <Typography variant="body2" sx={{ textAlign: "center", my: 1 }}>
            3) When both of you agree on a restaurant, it's a match!
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            sx={{ mt: 2 }}
          >
            <ActionButton
              variant="contained"
              color="primary"
              startIcon={<Sms />}
              onClick={sendLinkViaText}
            >
              Share Session Link via Text
            </ActionButton>
            <ActionButton
              variant="contained"
              color="primary"
              startIcon={<ContentCopyIcon />}
              onClick={copySessionLinkToClipboard}
            >
              Copy Session Link
            </ActionButton>
          </Stack>
          <ActionButton
            variant="contained"
            color="secondary"
            startIcon={<SwipeIcon />}
            onClick={handleStartSwiping}
            sx={{ mt: 4 }}
            disabled={!linkShared} // The button is disabled if the link hasn't been shared
          >
            Start Swiping
          </ActionButton>
        </CardContent>
      </Card>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </Container>
  );
};

export default SessionDetailPage;
