// Create a file named VenuesContext.js
import React, { createContext, useState } from "react";
export const VenueContext = createContext();

export const VenueProvider = ({ children }) => {
  const [venues, setVenues] = useState([]);
  const [currentVenueIndex, setcurrentVenueIndex] = useState(0);
  const [sessionId, setSessionId] = useState();
  const [authUser, setAuthUser] = useState({});
  const [currentUser, setCurrentUser] = useState([]);
  const [matches, setMatches] = useState([]);

  return (
    <VenueContext.Provider
      value={{
        venues,
        setVenues,
        currentVenueIndex,
        setcurrentVenueIndex,
        sessionId,
        setSessionId,
        authUser,
        setAuthUser,
        matches,
        setMatches,
        currentUser,
        setCurrentUser,
      }}
    >
      {children}
    </VenueContext.Provider>
  );
};
