import React from "react";
import { useTheme } from "@aws-amplify/ui-react";
import { Typography, Button, Container, Grid, Link } from "@mui/material"; // Import Material-UI components
import dinder_5 from "../images/Dinder_5.png"; // Adjust the path if necessary
import { useAuthenticator } from "@aws-amplify/ui-react";

export const AmplifyComponents = {
  Header() {
    const { tokens } = useTheme();

    const headerStyle = {
      paddingTop: "10vh", // Add 10vh of padding to the top
      backgroundColor: "#5B6A6D",
    };

    const imageStyle = {
      maxHeight: "10vh", // Set maximum height to 5vh
      maxWidth: "100%", // Set maximum width to 100% to maintain aspect ratio
    };

    return (
      <Container maxWidth="xs" style={headerStyle}>
        <Grid container justifyContent="center">
          <Grid item>
            <img alt="Amplify logo" src={dinder_5} style={imageStyle} />
          </Grid>
        </Grid>
      </Container>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <Container maxWidth="xs">
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              &copy; All Rights Reserved
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Container maxWidth="xs">
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="h5" align="center">
                Sign in to your account
              </Typography>
            </Grid>
          </Grid>
        </Container>
      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <Container maxWidth="xs">
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                variant="text"
                onClick={toForgotPassword}
                size="small"
                color="primary"
              >
                Reset Password
              </Button>
            </Grid>
          </Grid>
        </Container>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Container maxWidth="xs">
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="h5" align="center">
                Create a new account
              </Typography>
            </Grid>
          </Grid>
        </Container>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <Container maxWidth="xs">
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                variant="text"
                onClick={toSignIn}
                size="small"
                color="primary"
              >
                Back to Sign In
              </Button>
            </Grid>
          </Grid>
        </Container>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Container maxWidth="xs">
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="h5" align="center">
                Enter Information
              </Typography>
            </Grid>
          </Grid>
        </Container>
      );
    },
    Footer() {
      return (
        <Container maxWidth="xs">
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="body2">Footer Information</Typography>
            </Grid>
          </Grid>
        </Container>
      );
    },
  },
  SetupTotp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Container maxWidth="xs">
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="h5" align="center">
                Enter Information
              </Typography>
            </Grid>
          </Grid>
        </Container>
      );
    },
    Footer() {
      return (
        <Container maxWidth="xs">
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="body2">Footer Information</Typography>
            </Grid>
          </Grid>
        </Container>
      );
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Container maxWidth="xs">
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="h5" align="center">
                Enter Information
              </Typography>
            </Grid>
          </Grid>
        </Container>
      );
    },
    Footer() {
      return (
        <Container maxWidth="xs">
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="body2">Footer Information</Typography>
            </Grid>
          </Grid>
        </Container>
      );
    },
  },
  ForgotPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Container maxWidth="xs">
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="h5" align="center">
                Enter Information
              </Typography>
            </Grid>
          </Grid>
        </Container>
      );
    },
    Footer() {
      return (
        <Container maxWidth="xs">
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="body2">Footer Information</Typography>
            </Grid>
          </Grid>
        </Container>
      );
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Container maxWidth="xs">
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="h5" align="center">
                Enter Information
              </Typography>
            </Grid>
          </Grid>
        </Container>
      );
    },
    Footer() {
      return (
        <Container maxWidth="xs">
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="body2">Footer Information</Typography>
            </Grid>
          </Grid>
        </Container>
      );
    },
  },
};
