// TopVenues.js
import React from "react";
import { Card, CardMedia, CardContent, Typography, Grid } from "@mui/material";

const TopVenueCard = ({ venue }) => {
  return (
    <a
      href={venue.url}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      <Card sx={{ maxWidth: 345, m: 1 }}>
        <CardMedia
          component="img"
          height="140"
          image={venue.imageUrl}
          alt={venue.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {venue.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {venue.categories.map((category) => category.title).join(", ")}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {`${venue.rating} Stars (${venue.reviewCount} Reviews)`}
          </Typography>
          <Typography variant="body2" color="text.primary">
            Swipe Count: {venue.swipeCount}
          </Typography>
        </CardContent>
      </Card>
    </a>
  );
};

const TopVenues = ({ venues }) => {
  const topVenues = venues.slice(0, 4); // Get the top 4 venues
  return (
    <Grid container spacing={2} justifyContent="center">
      {topVenues.map((venue) => (
        <Grid item xs={6} key={venue.yelpId}>
          {" "}
          {/* xs={6} will create a 2x2 grid on extra small to medium devices */}
          <TopVenueCard venue={venue} />
        </Grid>
      ))}
    </Grid>
  );
};

export default TopVenues;
