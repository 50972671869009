// SummaryPage.js
import React from "react";
import { Typography, List, ListItem, ListItemText, Paper } from "@mui/material";

const SummaryPage = ({ location, price, categories }) => {
  // Helper function to format price level
  const formatPrice = (priceLevel) => {
    return "$".repeat(priceLevel);
  };

  // Helper function to get selected categories
  const getSelectedCategories = (categories) => {
    // Check if categories is an array
    if (Array.isArray(categories)) {
      // If it's an array, just join the elements with a comma
      return categories.join(", ");
    } else {
      // If it's an object, proceed as before
      return Object.entries(categories)
        .filter(([_, isSelected]) => isSelected)
        .map(([key, _]) => key)
        .join(", ");
    }
  };

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Dinder Selections
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Location"
            secondary={location || "Not specified"}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Price"
            secondary={price ? formatPrice(price) : "Not specified"}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Categories"
            secondary={getSelectedCategories(categories) || "Not specified"}
          />
        </ListItem>
      </List>
    </Paper>
  );
};

export default SummaryPage;
