// PriceSelect.js
import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

const PriceSelect = ({ price, setPrice, error }) => {
  return (
    <FormControl variant="outlined" fullWidth error={!!error}>
      <InputLabel>Price</InputLabel>
      <Select
        value={price}
        label="Price"
        onChange={(e) => setPrice(e.target.value)}
        // margin="normal"
      >
        {/* <MenuItem value="">
          <em>None</em>
        </MenuItem> */}
        {["$", "$$", "$$$", "$$$$"].map((option, index) => (
          <MenuItem key={option} value={String(index + 1)}>
            {option}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default PriceSelect;
