// RatingAndCategoryAnalysis.js
import React from "react";
import { Chip, Stack, Box, Typography } from "@mui/material";

const RatingAndCategoryAnalysis = (analysis) => {
  return (
    <Box>
      <Typography variant="h6">Ratings Analysis</Typography>
      {analysis.rankedRatings.map((item, index) => (
        <Chip
          key={index}
          label={`${item.rating} Stars - ${item.count} Venues`}
        />
      ))}

      <Typography variant="h6" sx={{ mt: 2 }}>
        Categories Analysis
      </Typography>
      {analysis.rankedCategories.map((item, index) => (
        <Chip key={index} label={`${item.category} - ${item.count}`} />
      ))}
    </Box>
  );
};

export default RatingAndCategoryAnalysis;
