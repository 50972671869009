import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { VenueContext } from "../context/VenueContext";
import {
  Container,
  Typography,
  Button,
  CircularProgress,
  CardContent,
  Card,
} from "@mui/material";
import SwipeIcon from "@mui/icons-material/Swipe";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { useAuth } from "../utils/useAuth"; // Import your custom useAuth hook
import { getSessionSearchDetails } from "../services/apiService";
import SummaryPage from "./session/SummaryPage";

const JoinSessionScreen = () => {
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth(); // Use your custom useAuth hook
  const sessionIdParam = useParams().sessionId;
  const [sessionSearchInformation, setSessionSearchInformation] =
    useState(null);
  const { setSessionId, setCurrentUser } = useContext(VenueContext);
  const [isLoading, setIsLoading] = useState(true);

  let searchInfo, startingUser, location, price, categories;

  if (sessionSearchInformation) {
    searchInfo = sessionSearchInformation.searchInfo;
    startingUser = sessionSearchInformation.startingUser;
    if (searchInfo) {
      location = searchInfo.location;
      price = searchInfo.price;
      categories = searchInfo.categories;
    }
  }

  useEffect(() => {
    console.log("user", user);
    const fetchSessionInformation = async () => {
      try {
        const fetchedSessionSearchInformation = await getSessionSearchDetails(
          sessionIdParam
        );
        setSessionSearchInformation(fetchedSessionSearchInformation);
      } catch (error) {
        console.error("Error fetching session information:", error);
        datadogLogs.logger.error("Failed to fetch session information", {
          error,
        });
        datadogRum.addError("Failed to fetch session information", error);
        // Handle any further error scenarios, e.g., redirecting the user
      } finally {
        setIsLoading(false);
      }
    };
    // Call the function
    fetchSessionInformation();
  }, [sessionId, navigate]); // Dependencies array,

  useEffect(() => {
    // Check if the user object is available
    if (user) {
      setIsLoading(false);
    }
  }, [user]);

  const handleJoinSession = () => {
    setSessionId(sessionIdParam);
    setCurrentUser(user);
    navigate(`/sessions/${sessionId}/swipe`);
  };

  if (isLoading || !sessionSearchInformation) {
    return (
      <Container
        maxWidth="sm"
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }
  return (
    <Container
      maxWidth="sm"
      sx={{
        height: "100vh",
        paddingTop: "10vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        mb: 2,
        overflowY: "auto",
        // maxHeight: "50vh",
      }}
    >
      <Typography variant="h4" gutterBottom align="center">
        You've been invited to join a dinder session!
      </Typography>
      <Typography variant="body1" align="center">
        1) Click join below!
      </Typography>
      <Typography variant="body1" align="center">
        2) Swipe right on restaurants you like!
      </Typography>
      <Typography variant="body1" align="center">
        3) When both of you agree on a restaurant, it's a match!
      </Typography>

      <Card
        sx={{
          mb: 2,
          overflowY: "auto",
        }}
      >
        <CardContent>
          <SummaryPage
            location={location}
            price={price}
            categories={categories}
          />
          <Typography variant="subtitle2">Host: {startingUser}</Typography>
        </CardContent>
      </Card>

      <Button
        variant="contained"
        color="primary"
        onClick={handleJoinSession}
        sx={{ mb: 2 }}
      >
        <SwipeIcon />
        Join and Start Swiping
      </Button>
    </Container>
  );
};

export default JoinSessionScreen;
