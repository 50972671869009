import axios from "axios";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Replace with your actual backend API URL

// Create a centralized Axios instance for API calls
const api = axios.create({
  baseURL: API_BASE_URL,
});

// Set default headers for all requests
api.defaults.headers.common["Content-Type"] = "application/json";
// Add any other headers you want to set globally, for example:
// api.defaults.headers.common['Authorization'] = 'Bearer yourAuthToken';

// Axios response interceptor
api.interceptors.response.use(
  (response) => {
    const traceId = response.headers["x-datadog-trace-id"];
    if (traceId) {
      datadogRum.addRumGlobalContext("trace_id", traceId);
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const logApiCall = (url, data, response) => {
  datadogLogs.logger.info("API Call", { url, data, response });
};

// API Function: Start Session
export const startSession = async (data) => {
  const url = `${API_BASE_URL}/sessions/start`;
  try {
    const response = await api.post(url, data);
    logApiCall(url, data, response.data);
    return response.data; // Assuming the backend sends back session data
  } catch (error) {
    datadogLogs.logger.error("Error starting session", { url, error });
    throw error;
  }
};

// API Function: Join Session
export const joinSession = async (sessionId, currentUser) => {
  const url = `${API_BASE_URL}/sessions/${sessionId}/join`;
  const data = {
    sessionId,
    currentUser,
  };

  try {
    const response = await api.post(url, data);

    logApiCall(url, null, response.data);
    return response.data;
  } catch (error) {
    datadogLogs.logger.error("Error joining session", { url, error });
    throw error;
  }
};

// API Function: Update Swipes
export const updateSwipes = async ({
  sessionId,
  user: userId,
  swipe: { yelpId, direction },
}) => {
  const url = `${API_BASE_URL}/sessions/${sessionId}/update-swipes`;
  const data = {
    sessionId,
    user: userId,
    swipe: { yelpId, direction },
  };
  try {
    const response = await api.post(url, data);
    logApiCall(url, data, response.data);
    // console.log(response.data, "data");
    return response.data;
  } catch (error) {
    datadogLogs.logger.error("Error updating swipe", { url, error });
    throw error;
  }
};

// Fetching a list of restaurants
export const fetchRestaurants = async () => {
  const url = `${API_BASE_URL}/restaurants`;
  try {
    const response = await api.get(url);
    logApiCall(url, null, response.data);
    return response.data;
  } catch (error) {
    datadogLogs.logger.error("Error fetching restaurants", { url, error });
    throw error;
  }
};

export const getSessionDetails = async (sessionId) => {
  const url = `${API_BASE_URL}/sessions/${sessionId}`;
  try {
    const response = await api.get(url);
    logApiCall(url, null, response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching session details:", error);
    datadogLogs.logger.error("Error fetching session details", { url, error });
    datadogRum.addError("Failed to fetch session details", error);
    throw error;
  }
};
export const getSessionSearchDetails = async (sessionId) => {
  const url = `${API_BASE_URL}/sessions/${sessionId}`;
  try {
    const response = await api.get(url);
    logApiCall(url, null, response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching session details:", error);
    datadogLogs.logger.error("Error fetching session details", { url, error });
    datadogRum.addError("Failed to fetch session details", error);
    throw error;
  }
};
//

export const getSessionWrappedDetails = async (sessionId) => {
  const url = `${API_BASE_URL}/sessions/wrapped/${sessionId}`;
  try {
    const response = await api.get(url);
    logApiCall(url, null, response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching session details:", error);
    datadogLogs.logger.error("Error fetching session details", { url, error });
    datadogRum.addError("Failed to fetch session details", error);
    throw error;
  }
};
