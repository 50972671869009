// src/components/CustomButton.js

import React from "react";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const CustomButton = ({ handleStartSession, text }) => {
  const theme = useTheme();

  return (
    <Button
      size="large"
      variant="contained"
      color="primary"
      onClick={handleStartSession}
      sx={{
        margin: theme.spacing(2), // Top and bottom margin
        marginTop: theme.spacing(2), // Additional top margin
        // Same horizontal padding for both sides
        padding: theme.spacing(1),
        // Explicitly set left and right margins to ensure they are the same
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        textTransform: "none",
        fontSize: theme.typography.button.fontSize,
        // The button should only fill the width of the CardContent minus padding
        width: `calc(100% - ${theme.spacing(4)})`, // Adjust the spacing value accordingly
      }}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
