// LocationInput.js
import React from "react";
import { TextField, MenuItem, FormControl } from "@mui/material";

const VenueSelect = ({ sessionType, setSessionType, error }) => {
  // Helper text for error state
  const helperText = error ? "This field is required" : "";

  return (
    <FormControl
      component="fieldset"
      variant="outlined"
      fullWidth
      error={error}
    >
      <TextField
        select
        labelId="venue-select-label"
        id="venue-select"
        value={sessionType}
        label="Bar or Restaurant?"
        onChange={(e) => setSessionType(e.target.value)}
        helperText={helperText}
        variant="outlined"
        size="small" // Assuming you want a smaller input size based on the screenshot
      >
        {/* <MenuItem value="">
          <em>None</em>
        </MenuItem> */}
        {["Bar", "Restaurant"].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      {/* {error && <FormHelperText>{helperText}</FormHelperText>} */}
    </FormControl>
  );
};

export default VenueSelect;
