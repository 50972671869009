import React from "react";
import { StepConnector, styled } from "@mui/material";

// Custom connector that changes color when active
export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  "&.Mui-active": {
    "& .MuiStepConnector-line": {
      borderColor: theme.palette.primary.main,
    },
  },
  "&.Mui-completed": {
    "& .MuiStepConnector-line": {
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiStepConnector-line": {
    transition: theme.transitions.create("border-color"),
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: ownerState.completed
    ? theme.palette.primary.main
    : theme.palette.mode === "dark"
    ? theme.palette.grey[700]
    : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: `radial-gradient(${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
}));

export function ColorlibStepIcon(props) {
  const { active, completed } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }}>
      {String(props.icon)}
    </ColorlibStepIconRoot>
  );
}
