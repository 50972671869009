import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { alpha } from "@mui/material/styles";

const ColoredButton = styled(Button)(({ theme, bgcolor, hoverbgcolor }) => ({
  // color: theme.palette.getContrastText(bgcolor),
  color: "#fff", // White text color
  borderColor: bgcolor,
  backgroundColor: bgcolor,
  "&:hover": {
    backgroundColor: hoverbgcolor || alpha(bgcolor, 0.8),
    borderColor: hoverbgcolor || alpha(bgcolor, 0.8),
  },
  // Rest of your styles...
}));

export default ColoredButton;
