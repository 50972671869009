// src/utils/useAuth.js
import { useState, useEffect } from "react";
import { getCurrentUser, signOut } from "aws-amplify/auth";

export const useAuth = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    getCurrentUser()
      .then((currentUser) => setUser(currentUser))
      .catch(() => setUser(null));
  }, []);

  const userSignOut = async () => {
    try {
      await signOut();
      setUser(null);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return { user, userSignOut };
};
