// src/theme/theme.js

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    // Define your color palette here
    primary: {
      // main: "#ff5722", // Example color
      main: "#0c6768", // Example color
    },
    secondary: {
      main: "#ffc107", // Example color
      // main: "#eedae0", // Example color
    },
    // Add other colors as needed
  },
  typography: {
    // Define typography settings for mobile-first design
    fontSize: 14,
    h5: {
      fontSize: "1.5rem", // Example for h5 heading
      "@media (min-width:600px)": {
        fontSize: "2.4rem",
      },
    },
    body1: {
      fontSize: "1rem",
      "@media (min-width:600px)": {
        fontSize: "1.2rem",
      },
    },
    // Define other text styles as needed
  },
  breakpoints: {
    // Define breakpoints for responsive design
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    // Override component styles here if necessary
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "12px 24px", // Example padding for buttons
        },
      },
    },
    // Add overrides for other components as needed
  },
});

export default theme;
