// src/components/HomePage.js

import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Container, Card, CardContent, Box } from "@mui/material";
import CustomButton from "./CustomButton"; // Custom button component
import dinder_5 from "../images/Dinder_5.png"; // Adjust the path if necessary
import { useTheme } from "@mui/material/styles";

const HomePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleStartSession = () => {
    navigate("/session");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", // Center content vertically
        alignItems: "center", // Center content horizontally
        height: "100vh", // Full height of the viewport
        padding: theme.spacing(2), // Apply padding from the theme
        boxSizing: "border-box", // Include padding in the height calculation
        gap: theme.spacing(4), // Space between card and image
      }}
    >
      <Box
        component="img"
        src={dinder_5}
        alt="dinder"
        sx={{
          maxWidth: "100%", // Ensures the image is not wider than the container
          maxHeight: "40vh", // Limits the height of the image to 40% of the viewport height
          width: "auto", // Maintains aspect ratio
          height: "auto", // Maintains aspect ratio
        }}
      />
      <Container maxWidth="sm" sx={{ width: "100%" }}>
        <Card raised sx={{ width: "100%", mb: 2 }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center", // Align card contents horizontally
              justifyContent: "center", // Align card contents vertically
            }}
          >
            <Typography gutterBottom variant="h5" component="div">
              Welcome to dinder
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Having trouble deciding where to eat? Let us help you!
            </Typography>
            <CustomButton
              handleStartSession={handleStartSession}
              text="Start a New Session"
            />
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default HomePage;
