// LocationInput.js
import React from "react";
import { TextField } from "@mui/material";

const LocationInput = ({ location, setLocation, error }) => {
  return (
    <TextField
      error={!!error}
      helperText={error}
      fullWidth
      label="What area do you want to go?"
      variant="outlined"
      value={location}
      onChange={(e) => setLocation(e.target.value)}
      // margin="normal"
    />
  );
};

export default LocationInput;
