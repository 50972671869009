// SessionPage.js
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stepper,
  Step,
  StepLabel,
  Box,
  Container,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import VenueSelect from "./SessionType";
import LocationInput from "./LocationInput";
import PriceSelect from "./PriceSelect";
import CategorySelect from "./CategorySelect";
import SummaryPage from "./SummaryPage";
import { VenueContext } from "../../context/VenueContext";
import { getCurrentUser } from "aws-amplify/auth";
import { startSession } from "../../services/apiService";
import { ColorlibConnector, ColorlibStepIcon } from "./CustomStepper";

const SessionPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [location, setLocation] = useState("");
  const [price, setPrice] = useState("");
  const [categories, setCategories] = useState({});
  const [sessionType, setSessionType] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [currAuthUser, setCurrAuthUser] = useState({}); // New authUser state
  const navigate = useNavigate(); // Add this line
  const { setVenues, setSessionId, setCurrentUser } = useContext(VenueContext);

  // const { route } = useAuthenticator((context) => [context.route]);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const contigoUser = await getCurrentUser();
        setCurrAuthUser(contigoUser);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    loadUser();
  }, []);

  //   useEffect(() => {
  //     if (route !== "authenticated") {
  //       navigate("/");
  //     }
  //   }, [route, navigate]);
  const steps = ["Venue", "Location", "Price", "Categories", "Review & Start"];

  const handleNext = () => {
    if (!validateCurrentStep()) return;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validateCurrentStep = () => {
    // Initialize an error object with empty strings for each field
    let newError = { sessionType: "", location: "", price: "", categories: "" };

    // Check which step is active and perform validation accordingly
    switch (activeStep) {
      case 0:
        if (!sessionType) newError.sessionType = "Venue is required";
        break;
      case 1: // Location step
        if (!location) newError.location = "Location is required";
        break;
      case 2: // Price step
        if (!price) newError.price = "Price is required";
        break;
      case 3: // Categories step
        // Since categories is an object of booleans, check if at least one category is selected
        const isCategorySelected = Object.values(categories).some(
          (value) => value
        );
        if (!isCategorySelected)
          newError.categories = "At least one category is required";
        break;
      case 4: // Review & Start step
        // Normally validation is not needed in the final review step
        // But if you need to do any final checks, do them here
        break;
      default:
        throw new Error("Unknown step");
    }

    // Set the error state
    setError(newError);

    // Check if there are any error messages set
    return !Object.values(newError).some((errorMsg) => errorMsg !== "");
  };

  // Call this function when the "Start Session" button is clicked
  const handleStartSession = async () => {
    // Assuming `validateCurrentStep` replaces the old `validateForm` and is suitable for the final validation before starting the session
    if (validateCurrentStep()) {
      setLoading(true);

      try {
        // Construct the categories as a comma-separated string if needed, or as an array
        const selectedCategories = Object.keys(categories).filter(
          (key) => categories[key]
        );
        const categoriesString = selectedCategories.join(", "); // If API expects a string

        const sessionData = await startSession({
          sessionType,
          location,
          categories: categoriesString, // or categoriesArray, depending on your backend API
          price,
          currAuthUser,
        });

        setVenues(sessionData.venues);
        setSessionId(sessionData.sessionId);
        setCurrentUser(sessionData.authUser);
        navigate(`/sessions/${sessionData.sessionId}`);
      } catch (error) {
        console.error("Error starting session:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <VenueSelect
            sessionType={sessionType}
            setSessionType={setSessionType}
            error={error.sessionType}
          />
        );
      case 1:
        return (
          <LocationInput
            location={location}
            setLocation={setLocation}
            error={error.location}
          />
        );
      case 2:
        return (
          <PriceSelect price={price} setPrice={setPrice} error={error.price} />
        );
      case 3:
        return (
          <CategorySelect
            sessionType={sessionType}
            categories={categories}
            setCategories={setCategories}
            error={error.categories}
          />
        );
      case 4:
        return (
          <React.Fragment>
            <SummaryPage
              location={location}
              price={price}
              categories={categories}
            />
          </React.Fragment>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Container maxWidth="sm" sx={{ pb: "10vh", pt: "10vh" }}>
      <Typography variant="h4" align="center" sx={{ mt: 4, mb: 4 }}>
        Find Your Meal
      </Typography>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        sx={{
          pb: 4,
          justifyContent: "center",
          "& .MuiStep-root": { justifyContent: "center" },
        }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box
        sx={{
          mb: 4,
          minHeight: "200px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {getStepContent(activeStep)}
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            p: 2,
            bgcolor: "background.paper",
            borderTop: "1px solid",
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          {activeStep < steps.length - 1 ? (
            <Button onClick={handleNext}>Next</Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleStartSession}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Start Session"}
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default SessionPage;
//
