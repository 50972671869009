import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Stack,
  CircularProgress,
  Container,
  Typography,
  Grid,
} from "@mui/material";
import { getSessionWrappedDetails } from "../services/apiService";
import dinder_5 from "../images/Dinder_5.png";

import TopVenues from "./wrapped/TopVenues";
import RatingAndCategoryAnalysis from "./wrapped/RatingAndCategoryAnalysis";
import usePolling from "./wrapped/usePolling";

import { sessionWrappedExample } from "./wrapped/wrappedExample";

const SessionWrapped = () => {
  const { sessionId } = useParams();
  const [sessionDetails, setSessionDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Define the function to update session details
  const updateSessionDetails = async () => {
    // setIsLoading(true);
    try {
      const details = await getSessionWrappedDetails(sessionId);
      console.log("polling");
      // Check if the new details are different from the current state before updating
      if (JSON.stringify(details) !== JSON.stringify(sessionDetails)) {
        setSessionDetails(details);
      }
    } catch (error) {
      console.error("Error fetching session details:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  // Call the polling hook with the update function, the polling delay, and the duration
  usePolling(updateSessionDetails, 1000, 60000);

  // Initial fetch of the session details
  useEffect(() => {
    updateSessionDetails();
  }, [sessionId]);

  if (isLoading || !sessionDetails) {
    return (
      <Container
        maxWidth="sm"
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (isLoading) {
    return (
      <Container
        maxWidth="sm"
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  const imageStyle = {
    maxHeight: "10vh", // Set maximum height to 5vh
    maxWidth: "100%", // Set maximum width to 100% to maintain aspect ratio
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{
        paddingTop: "10vh", // Reduced from 30vh to 5vh
        minHeight: "90vh", // Ensure that it is at least 90vh tall
        width: "100vw",
        overflowY: "auto", // Allows for scrolling if the content is larger than the container
        position: "relative",
        boxSizing: "border-box",
        "::-webkit-scrollbar": { width: "10px" },
        "::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 5px grey",
          borderRadius: "10px",
        },
        "::-webkit-scrollbar-thumb": {
          background: "darkgrey",
          borderRadius: "10px",
        },
      }}
    >
      {" "}
      <Grid item>
        <img alt="Amplify logo" src={dinder_5} style={imageStyle} />
      </Grid>
      <Typography variant="h6">Session Wrapped</Typography>
      <Typography variant="h8">Top Venues</Typography>
      <TopVenues venues={sessionDetails.matches.matchedVenues} />
      <RatingAndCategoryAnalysis {...sessionDetails.matches.analysis} />
      {/* Render your session details here */}
      {sessionDetails && (
        <div>
          {/* Example: Display session name */}
          <Typography>{sessionDetails.name}</Typography>
        </div>
      )}
    </Stack>
  );
};

export default SessionWrapped;
