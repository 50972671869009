export const restaurantCategoryOptions = [
  "American (Traditional)",
  "Mexican",
  "Italian",
  "Chinese",
  "Japanese",
  "Thai",
  "Korean",
  "Vietnamese",
  "Indian",
  "French",
  "Greek",
  "Spanish",
  "Mediterranean",
  "Lebanese",
  "Barbeque",
  "Seafood",
  "Soul Food",
  "Steakhouses",
  "Cajun/Creole",
  "Sushi",
  "Vegetarian",
  "Vegan",
];

export const barCategoryOptions = [
  "Beer Bar",
  "Champagne Bars",
  "Cigar Bars",
  "Cocktail Bars",
  "Dive Bars",
  "Gay Bars",
  "Hookah Bars",
  "Hotel Bar",
  "Irish Pub",
  "Lounges",
  "Pubs",
  "Sake Bars",
  "Speakeasies",
  "Sports Bars",
  "Tiki Bars",
  "Whiskey Bars",
  "Wine Bars",
];
