// RestaurantCard.js
import React from "react";
import { useSwipeable } from "react-swipeable";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Stack,
  CircularProgress,
  Box,
} from "@mui/material";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ColoredButton from "./actions/ColoredButton";

const VenueCard = ({ venue, handleSwipe }) => {
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe(yelpId, "left"),
    onSwipedRight: () => handleSwipe(yelpId, "right"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  if (!venue) {
    return (
      <Stack spacing={2} alignItems="center">
        <CircularProgress />
        <Typography variant="h6">
          You've reached the end of the restaurants, please wait while the other
          user finishes swiping!
        </Typography>
      </Stack>
    );
  }

  // Destructure properties from restaurant now that we know it's not null
  const {
    name,
    imageUrl,
    url,
    reviewCount,
    rating,
    price,
    location,
    displayPhone,
    yelpId,
    categories,
  } = venue;

  const handleClickLeft = () => handleSwipe(yelpId, "left");
  const handleClickRight = () => handleSwipe(yelpId, "right");

  return (
    <Card
      {...swipeHandlers}
      raised
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "calc(95vh - 100px)", // Adjust for top and bottom bars
        position: "relative", // Allows absolute positioning relative to the card
        backgroundColor: "#ffffff", // Ensure there's a background for transparent images
      }}
    >
      <CardMedia
        component="img"
        image={imageUrl}
        alt={name}
        sx={{ height: "45%", objectFit: "cover" }}
      />
      <CardContent
        sx={{
          flexGrow: 1,
          overflowY: "scroll",
          "::-webkit-scrollbar": { width: "10px" },
          "::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 5px grey",
            borderRadius: "10px",
          },
          "::-webkit-scrollbar-thumb": {
            background: "darkgrey",
            borderRadius: "10px",
          },
          paddingBottom: "10vh",
        }}
      >
        <Typography gutterBottom variant="h4" component="div">
          {name}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {categories.map((category) => category.title).join(", ")}
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{ cursor: "pointer", marginBottom: "8px" }}
          onClick={() => window.open(url, "_blank")}
        >
          {`${price} - ${rating} stars (${reviewCount} reviews)`}
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{ marginBottom: "8px" }}
        >
          {location}
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{ marginBottom: "8px" }}
        >
          {displayPhone}
        </Typography>
        <Button
          size="small"
          color="primary"
          href={url}
          target="_blank"
          sx={{ my: 2 }}
        >
          Learn More
        </Button>
      </CardContent>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: "10vh", // Fixed height for button container
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          // bgcolor: "primary.dark", // A distinct background for the buttons
          // opacity: 0.6,
          // bgcolor: alpha("primary.dark", 0.5), // Apply alpha for opacity
          borderTop: "1px solid", // Adding border top to the button block
          borderColor: "grey.500", // Border color to match your design
          zIndex: 1, // Ensure it's above the content for scrolling effect
          bgcolor: "white", // Changing the background color to white
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Adding a subtle shadow for depth
        }}
      >
        <ColoredButton
          variant="outlined"
          bgcolor="#F44336" // Red background for dislike button
          hoverbgcolor="#D32F2F" // Darker red on hover
          onClick={handleClickLeft}
          startIcon={<ThumbDownAltIcon />}
        >
          Dislike
        </ColoredButton>
        <ColoredButton
          variant="outlined"
          bgcolor="#4CAF50" // Green background for like button
          hoverbgcolor="#388E3C" // Darker green on hover
          onClick={handleClickRight}
          startIcon={<ThumbUpAltIcon />}
        >
          Like
        </ColoredButton>
      </Box>
    </Card>
  );
};

export default VenueCard;
