// CategorySelect.js
import React from "react";
import { FormControl, FormLabel, Chip, Paper } from "@mui/material";
import {
  restaurantCategoryOptions,
  barCategoryOptions,
} from "./VenueCategories";

const CategorySelect = ({ sessionType, categories, setCategories, error }) => {
  let categoryOptions =
    sessionType === "Restaurant"
      ? restaurantCategoryOptions
      : barCategoryOptions;

  const handleSelectCategory = (category) => {
    // Count how many categories are currently selected
    const selectedCount = Object.values(categories).filter(Boolean).length;

    // If the selected category is already selected, deselect it
    if (categories[category]) {
      setCategories({ ...categories, [category]: false });
    } else if (selectedCount < 3) {
      // Only allow a new selection if less than 3 categories are selected
      setCategories({ ...categories, [category]: true });
    } else {
      // If 3 categories are already selected, ignore the new selection or give feedback
      console.log("Maximum of 3 categories can be selected.");
      // You could also set an error state here to show a message to the user
    }
  };

  return (
    <FormControl component="fieldset" variant="standard" fullWidth>
      <FormLabel component="legend">Select up to 3 Categories</FormLabel>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          listStyle: "none",
          p: 0.5,
          m: 0,
        }}
        component="ul"
      >
        {categoryOptions.map((category) => {
          return (
            <li key={category}>
              <Chip
                label={category}
                onClick={() => handleSelectCategory(category)}
                color={categories[category] ? "primary" : "default"}
                sx={{ m: 0.5 }}
              />
            </li>
          );
        })}
      </Paper>
      {/* Optionally show an error message if there's an error */}
      {error && <FormLabel error={true}>{error}</FormLabel>}
    </FormControl>
  );
};

//   return (
//     <FormControl component="fieldset" variant="standard" fullWidth>
//       <FormLabel component="legend">Select Categories</FormLabel>
//       <Paper
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           flexWrap: "wrap",
//           listStyle: "none",
//           p: 0.5,
//           m: 0,
//         }}
//         component="ul"
//       >
//         <li>
//           <Chip
//             label="All"
//             onClick={handleSelectAll}
//             color={categories["All"] ? "primary" : "default"}
//             sx={{ m: 0.5 }}
//           />
//         </li>
//         {categoryOptions.map((category) => {
//           return (
//             <li key={category}>
//               <Chip
//                 label={category}
//                 onClick={() => handleSelectCategory(category)}
//                 color={categories[category] ? "primary" : "default"}
//                 sx={{ m: 0.5 }}
//               />
//             </li>
//           );
//         })}
//       </Paper>
//     </FormControl>
//   );
// };

export default CategorySelect;

// const restaurantCategoryOptions = [
//   "American (Traditional)",
//   "Mexican",
//   "Italian",
//   "Chinese",
//   "Japanese",
//   "Thai",
//   "Korean",
//   "Vietnamese",
//   "Indian",
//   "French",
//   "Greek",
//   "Spanish",
//   "Mediterranean",
//   "Lebanese",
//   "Barbeque",
//   "Seafood",
//   "Soul Food",
//   "Steakhouses",
//   "Cajun/Creole",
//   "Sushi",
//   "Vegetarian",
//   "Vegan",
// ];

// const barCategoryOptions = [
//   "Bars",
//   "Beer Bar",
//   "Champagne Bars",
//   "Cigar Bars",
//   "Cocktail Bars",
//   "Dive Bars",
//   "Gay Bars",
//   "Hookah Bars",
//   "Hotel Bar",
//   "Irish Pub",
//   "Lounges",
//   "Pubs",
//   "Sake Bars",
//   "Speakeasies",
//   "Sports Bars",
//   "Tiki Bars",
//   "Whiskey Bars",
//   "Wine Bars",
// ];
