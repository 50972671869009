import { useState, useEffect } from "react";

const usePolling = (pollingFunction, delay, duration) => {
  useEffect(() => {
    const poll = async () => {
      // Your polling function should handle updating state or whatever it needs to do
      await pollingFunction();
    };

    // Start polling
    const intervalId = setInterval(poll, delay);

    // Stop polling after a specific duration
    const timeoutId = setTimeout(() => clearInterval(intervalId), duration);

    // Clean up
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [pollingFunction, delay, duration]); // Add dependencies here
};

export default usePolling;

// import { useState, useEffect } from "react";

// const usePolling = (sessionId, getSessionWrappedDetails) => {
//   const [isActive, setIsActive] = useState(true);

//   useEffect(() => {
//     let intervalId;
//     let timeoutId;

//     const poll = () => {
//       // Check if the webpage is active
//       if (document.visibilityState === "visible") {
//         getSessionWrappedDetails(sessionId);
//       }
//     };

//     if (isActive) {
//       // Call the function immediately, then set up the interval
//       poll();
//       intervalId = setInterval(poll, 5000); // Poll every 5 seconds
//       console.log("Polling");

//       // Set a timeout to stop the polling after 1 minute
//       timeoutId = setTimeout(() => {
//         clearInterval(intervalId);
//         setIsActive(false); // Stop the polling after 1 minute
//       }, 60000);
//     }

//     // Clean up the interval and timeout when the component is unmounted or the isActive changes
//     return () => {
//       clearInterval(intervalId);
//       clearTimeout(timeoutId);
//     };
//   }, [isActive, sessionId, getSessionWrappedDetails]);

//   // Expose the ability to manually stop the polling
//   const stopPolling = () => setIsActive(false);

//   return { stopPolling };
// };

// export default usePolling;
