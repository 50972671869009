// ActionButton.js
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const ActionButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5),
  textTransform: "none", // Keeps the case of the text as defined
  margin: theme.spacing(1), // Ensures buttons don't touch each other
  // Add more styles here if needed
}));

export default ActionButton;
