import React, { useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import VenueCard from "./VenueCard";
import { VenueContext } from "../context/VenueContext";
import { updateSwipes } from "../services/apiService";
import io from "socket.io-client";
import { useNavigate } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import { Stack, CircularProgress, Container, Typography } from "@mui/material";
import { getCurrentUser } from "aws-amplify/auth";
import { datadogLogs } from "@datadog/browser-logs";
import { joinSession } from "../services/apiService";
import { useAuth } from "../utils/useAuth";

const FoodDojo = () => {
  const {
    venues,
    setVenues,
    currentVenueIndex,
    setcurrentVenueIndex,
    sessionId,
    setMatches,
    setSessionId,
  } = useContext(VenueContext);

  const navigate = useNavigate();
  const sessionIdParam = useParams().sessionId;
  const { user } = useAuth(); // Use your custom useAuth hook

  // const socketRef = useRef(null); // Define the socketRef using useRef

  useEffect(() => {
    let isComponentMounted = true; // Flag to track mounted state

    const joinAndSetSession = async () => {
      try {
        const currentUser = await getCurrentUser();
        if (currentUser) {
          const sessionInformation = await joinSession(
            sessionIdParam,
            currentUser
          );
          if (isComponentMounted) {
            setSessionId(sessionIdParam);
            const randomizedVenues = shuffleArray(sessionInformation.venues);
            setVenues(randomizedVenues);
          }

          // if (!socketRef.current) {
          //   socketRef.current = io(process.env.REACT_APP_API_BASE_URL);
          //   socketRef.current.emit("join-room", sessionIdParam);

          //   // socketRef.current.on("match-found", (matchedRestaurant) => {
          //   //   if (isComponentMounted) {
          //   //     datadogLogs.logger.info("Match Found", { matchedRestaurant });
          //   //     setMatches(matchedRestaurant);
          //   //     navigate(`/match/${matchedRestaurant.restaurantId}`);
          //   //     datadogRum.addAction("Match Found", { matchedRestaurant });
          //   // }
          //   // });
          // }
        } else {
          navigate("/signin"); // Redirect to sign-in if there is no current user
        }
      } catch (error) {
        console.error("Error joining session:", error);
        datadogLogs.logger.error("Failed to join session", { error });
        datadogRum.addError("Failed to join session", error);
        if (isComponentMounted) {
          navigate("/error"); // Redirect to an error page or handle the error
        }
      }
    };

    joinAndSetSession();

    // Cleanup function to run when the component unmounts or dependencies change
    // return () => {
    //   isComponentMounted = false; // Set the flag to false when the component unmounts
    //   if (socketRef.current) {
    //     socketRef.current.off("match-found");
    //     socketRef.current.disconnect();
    //     socketRef.current = null;
    // }
    // };
  }, [
    sessionId,
    navigate,
    setVenues,
    setSessionId,
    setMatches,
    user,
    sessionIdParam,
  ]);

  // Utility function to shuffle an array of restaurants cards
  function shuffleArray(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const handleSwipe = async (yelpId, direction) => {
    try {
      const currentUser = await getCurrentUser();
      const userId = currentUser.username; // Or another identifier from the user object
      // Update the swipe on the backend
      const response = await updateSwipes({
        sessionId,
        user: userId,
        swipe: { yelpId, direction },
      });

      if (currentVenueIndex === venues.length - 1) {
        navigate(`/sessionWrapped/${sessionId}`);
      } else {
        setcurrentVenueIndex(currentVenueIndex + 1);
        datadogLogs.logger.info("Swipe updated", { response });
      }
    } catch (error) {
      console.error("Error updating swipe:", error);
    }
  };

  // Check if the restaurants are loaded before rendering the RestaurantCard
  if (!venues) {
    return (
      <Container
        maxWidth="sm"
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{
        paddingTop: "10vh",
        height: "90vh", // Adjusted to account for the top and bottom bars without additional padding
        width: "100vw", // Ensure the stack takes the full viewport width
        overflow: "auto", // Add scroll to the Stack if content overflows
        position: "relative", // Ensures that this stack is positioned relatively for absolute children
        boxSizing: "border-box", // Include padding and borders in the element's total width and height
      }}
    >
      {venues.length > currentVenueIndex ? (
        <VenueCard
          venue={venues[currentVenueIndex]}
          handleSwipe={handleSwipe}
        />
      ) : (
        <Typography variant="h6">...Venues Loading...</Typography>
      )}
    </Stack>
  );
};

export default FoodDojo;

// useEffect(() => {
//   const joinAndSetSession = async () => {
//     try {
//       const currentUser = await getCurrentUser();
//       if (currentUser) {
//         const sessionInformation = await joinSession(sessionIdParam, user);
//         setSessionId(sessionIdParam);
//         const socket = io(process.env.REACT_APP_API_BASE_URL);
//         socket.emit("join-room", sessionId);
//         const randomizedRestaurants = shuffleArray([
//           ...sessionInformation.restaurants,
//         ]);
//         setRestaurants(randomizedRestaurants);

//         if (!socketRef.current) {
//           socketRef.current = io(process.env.REACT_APP_API_BASE_URL);
//           socketRef.current.emit("join-room", sessionIdParam);

//           socketRef.current.on("match-found", (matchedRestaurant) => {
//             // socket.on("match-found", (matchedRestaurant) => {
//             datadogLogs.logger.info("Match Found", { matchedRestaurant });
//             setMatches(matchedRestaurant);
//             navigate(`/match/${matchedRestaurant.restaurantId}`);
//             datadogRum.addAction("Match Found", { matchedRestaurant });
//           });
//         }
//       } else {
//         navigate("/");
//       }
//     } catch (error) {
//       console.error("Error joining session:", error);
//       datadogLogs.logger.error("Failed to join session", { error });
//       datadogRum.addError("Failed to join session", error);
//       // navigate("/signin"); // Redirect to sign-in if there is an error
//     }
//   };

//   joinAndSetSession();
//   return () => {
//     if (socketRef.current) {
//       socketRef.current.off("match-found");
//       socketRef.current.disconnect();
//       socketRef.current = null; // Clean up the ref
//     }
//   };
// }, [
//   sessionId,
//   navigate,
//   setRestaurants,
//   setSessionId,
//   setMatches,
//   user,
//   sessionIdParam,
// ]);
